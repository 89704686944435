@tailwind base;
@tailwind components;
@tailwind utilities;

.f-col {
  @apply flex flex-col;
}
.f-row {
  @apply flex flex-row;
}
.centered {
  @apply items-center justify-center;
}

html,
#root,
body {
  overscroll-behavior: none;
}

@font-face {
  font-family: 'Licorice';
  src: url('/public/Licorice-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.primary-shadow {
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.25);
}

.primary-input-style {
  background: #ffffff;
  /* gray/400 */

  border: 1px solid #9ca3af;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25), inset 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
}

.f-col-center {
  @apply flex flex-col items-center;
}

.f-col-center-center {
  @apply flex flex-col items-center justify-center;
}

.f-row-between {
  @apply flex justify-between;
}
.f-row-center {
  @apply flex justify-center;
}

.f-row-center-center {
  @apply flex items-center justify-center;
}

.input-style {
  @apply primary-input-style w-full border border-gray-300 py-2 px-2 text-sm text-gray-800 focus:border-gray-400 focus:outline-none focus:ring-1;
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
span,
a,
label,
button,
input,
select,
small,
textarea,
table,
tr,
td,
th,
thead,
tbody,
tfoot,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Inter', sans-serif;
}
input,
input::placeholder {
  font: 14px/3 sans-serif;
  color: #ababab;
}

.otp-input-custom {
  width: 40px !important;
  height: 40px !important;
}
@media (max-width: 425px) {
  .otp-input-custom {
    margin-right: 10px !important;
    width: 45px !important;
    height: 45px !important;
  }
}

.labl {
  display: block;
  border-radius: 5px;
}
.labl > input {
  /* HIDE RADIO */
  visibility: hidden; /* Makes input not-clickable */
  /*position: absolute; !* Remove input from document flow *!*/
}
.labl > input + div {
  /* DIV STYLES */
  cursor: pointer;
  border: 1px solid #b0b0b0;
}
.labl > input:checked + div {
  /* (RADIO CHECKED) DIV STYLES */
  background-color: #ffd6bb;
  border: 1px solid #ff6600;
  color: #672804;
}

.menu-wrap {
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.menu-wrap::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}
.menu-wrap-y-dashboard {
  overflow-y: scroll;
  scrollbar-width: auto; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.menu-wrap-y-dashboard::-webkit-scrollbar {
  /* WebKit */
  width: 5px;
  height: 5px;
}

/* For webkit-based browsers */
.menu-wrap-y-dashboard::-webkit-scrollbar-thumb {
  background-color: #a0aec0;
  border-radius: 10px; /* add border radius */
}

.menu-wrap-x {
  overflow-x: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.menu-wrap-x::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}
.menu-wrap-x-show {
  overflow-x: scroll;
  scrollbar-width: auto; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  -moz-scrollbar-width: auto; /* Older Firefox */
}
.menu-wrap-x-show::-webkit-scrollbar {
  height: 5px;
}
.menu-wrap-x-show::-webkit-scrollbar-thumb {
  background-color: #ccc; /* Color of the thumb */
}

.input-phone-number input {
  background: #ffffff;
  /* gray/400 */
  @apply primary-input-style w-full border border-gray-300 py-2 px-2 text-sm text-gray-800 focus:border-gray-400 focus:outline-none focus:ring-1;
}

.my-dropdown-class {
  width: 500px; /* set the desired width */
}
.modal {
  animation: pop-up 0.3s ease-in-out;
  animation-fill-mode: both;
  opacity: 0;
  transform: scale(0.8);
}

@keyframes pop-up {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@media only screen and (max-width: 1023px) {
  .sidebar {
    animation: sidebar 0.5s ease-in-out forwards;
  }
}
@keyframes sidebar {
  0% {
    opacity: 1;
    transform: translateX(-250px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
